<template>
    <div>
        <PriceFilter v-if="$can('filter', 'allergies')" :loading="loading" @searchFilter="searchFilter"
            @filterPage="filterPage" />
        <b-card>
            <TitleTable titleTable="Price" iconTable="far fa-file-alt" />
            <div class="m-1">
                <PriceEdit v-if="isEditPriceSidebarActive" :is-edit-price-sidebar-active.sync="isEditPriceSidebarActive"
                    :itemEdit="itemEdit" @editPriceItem="editPriceItem" />
                <PriceAdd :is-add-new-price-sidebar-active.sync="isAddNewPriceSidebarActive" @createPrice="createPrice" />
                <!-- Table Top -->
                <b-row>
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <treeselect v-model="perPage" :options="perPageOptions" :clearable="true" class="w-25" />
                    </b-col>
                    <!-- {{ $t("Search") }} -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <GoBack class="mr-2" />
                            <b-button v-if="$can('store', 'allergies')" variant="primary"
                                @click="isAddNewPriceSidebarActive = true" :disabled="loading">
                                <span v-if="!loading" class="text-nowrap">{{ $t("Add") }} Price</span>
                                <span v-else>
                                    <SpinnerLoading />
                                </span>
                            </b-button>
                        </div>
                    </b-col>
                </b-row>
            </div>

            <b-table :items="items" :fields="fields" hover bordered responsive primary-key="id" class="position-relative"
                show-empty empty-text="No matching records found">
                <template #cell(status)="data">
                    <div class="text-nowrap">
                        <span class="align-text-top text-capitalize">{{
                            data.item.status ? $t("Active") : $t("Inactive")
                        }}</span>
                    </div>
                </template>
                <template #cell(actions)="data">
                    <b-button v-if="$can('update', 'allergies')" variant="outline-primary"
                        class="btn-icon btn-circle border-0" v-b-tooltip.hover.top="'Edit'" @click="editPrice(data.item)">
                        <i class="fas fa-pen icon-nm"></i>
                    </b-button>

                    <b-button v-if="$can('destroy', 'allergies')" variant="outline-danger"
                        class="btn-icon btn-circle border-0" v-b-tooltip.hover.top="'Delete'"
                        @click="deletePrice(data.item)">
                        <i class="fas fa-trash-alt icon-nm"></i>
                    </b-button>
                </template>
            </b-table>
            <PaginationTable :dataMeta="dataMeta" :totalUsers="totalUsers" :perPage="perPage" :currentPage="currentPage"
                @page-changed="pageChanged" />
        </b-card>
        <ToastNotification ref="toast" />
    </div>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BTable,
    BBadge,
    BAvatar,
    BButton,
    VBTooltip,
} from "bootstrap-vue";
import axiosAC from "@/core/services/apiInsurance/admin/consults/consultPrices";

import ToastNotification from "@/components/ToastNotification";
import SpinnerLoading from "@/components/SpinnerLoading";
import PaginationTable from "@/components/PaginationTable";
import TitleTable from "@/components/TitleTable.vue";
import GoBack from "@/components/ButtonBack.vue";
import Swal from "sweetalert2";

import PriceEdit from "./PriceEdit.vue";
import PriceAdd from "./PriceAdd.vue";
import PriceFilter from "./PriceFilter.vue";
import { ref } from "@vue/composition-api";
export default {
    components: {
        BCard,
        BRow,
        BCol,
        BTable,
        BBadge,
        BAvatar,
        BButton,
        PriceEdit,
        PriceAdd,
        PriceFilter,
        ToastNotification,
        SpinnerLoading,
        PaginationTable,
        TitleTable,
        GoBack,
    },
    directives: {
        "b-tooltip": VBTooltip,
    },
    data() {
        return {
            fields: [
                "id",
                { key: "name", label: "Name", sortable: true },
                { key: "price", label: "Price", sortable: true },
                { key: "description", label: "Description" },
                { key: "default", label: "Default", sortable: true },
                { key: "status", label: "Status" },
                { key: "actions", label: "Actions" },
            ],
            items: null,
            loading: false,
            isEditPriceSidebarActive: false,
            isAddNewPriceSidebarActive: false,
            itemEdit: null,
            dataMeta: { from: 1, to: 1, of: 1 },
            totalUsers: 0,
            perPage: 10,
            currentPage: {
                page: 1,
            },
            perPageOptions: [10, 25, 50, 100].map((i) => ({
                id: i,
                label: `Show ${i} Entries`,
            })),
            filtro: false,
            priceFilter: null,
        };
    },
    mounted() {
        this.getPrices();
    },
    watch: {
        perPage(newVal) {
            if (!this.filtro) {
                this.getPrices();
            } else {
                this.searchFilter(this.priceFilter);
            }
        },
    },
    methods: {
        getPrices() {
            axiosAC
                .consultPriceList(this.perPage)
                .then(({ data, current_page, total, per_page, ...res }) => {
                    this.items = data;
                    this.totalUsers = total;
                    this.dataMeta.of = total;
                    this.dataMeta.from = res.from;
                    this.dataMeta.to = res.to;
                    this.currentPage.page = current_page;
                });
        },
        editPrice(item) {
            this.isEditPriceSidebarActive = true;
            this.itemEdit = ref(JSON.parse(JSON.stringify(item)));
        },
        editPriceItem(value) {
            if (value) {
                Swal.fire({
                    title: "",
                    text: "Price type added successfully",
                    icon: "success",
                    confirmButtonClass: "btn btn-secondary",
                });
            } else {
                Swal.fire({
                    title: "",
                    text: "Price type not added",
                    icon: "error",
                    confirmButtonClass: "btn btn-secondary"
                });
            }
            this.isEditPriceSidebarActive = false;
            this.pageChanged(this.currentPage.page);
        },
        createPrice({ status }) {
            if (status) {
                Swal.fire({
                    title: "",
                    text: "Price type added successfully",
                    icon: "success",
                    confirmButtonClass: "btn btn-secondary",
                });
            } else {
                Swal.fire({
                    title: "",
                    text: "Price type not added",
                    icon: "error",
                    confirmButtonClass: "btn btn-secondary"
                });
            }
            this.isAddNewPriceSidebarActive = false;
            this.pageChanged(this.currentPage.page);
        },
        deletePrice(item) {
            Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this",
                icon: "error",
                showCancelButton: true,
                confirmButtonText: "Yes, delete it",
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-outline-danger ml-1",
                },
                buttonsStyling: false,
            }).then((result) => {
                if (result.value) {
                    axiosAC.consultPriceDelete(item.id).then((response) => {
                        this.$refs.toast.success(
                            "Price type has been deleted successfully"
                        );
                        this.pageChanged(this.currentPage.page);
                    });
                }
            });
        },
        pageChanged(page) {
            if (!this.filtro) {
                axiosAC
                    .consultPricePagination(this.perPage, page)
                    .then(({ data, total, current_page, ...res }) => {
                        this.items = data;
                        this.totalUsers = total;
                        this.dataMeta.of = total;
                        this.dataMeta.from = res.from;
                        this.dataMeta.to = res.to;
                        this.currentPage.page = current_page;
                    });
            } else {
                axiosAC
                    .consultPriceFilterPagination(this.perPage, page, this.priceFilter)
                    .then(({ data, total, current_page, ...res }) => {
                        this.items = data;
                        this.totalUsers = total;
                        this.dataMeta.of = total;
                        this.dataMeta.from = res.from;
                        this.dataMeta.to = res.to;
                        this.currentPage.page = current_page;
                    });
            }
        },
        searchFilter(value) {
            if (this.filtro) {
                this.loading = true;
                this.priceFilter = value;
                axiosAC
                    .consultPriceFilter(this.perPage, value)
                    .then(({ data, total, current_page, ...res }) => {
                        this.loading = false;
                        this.items = data;
                        this.totalUsers = total;
                        this.dataMeta.of = total;
                        this.dataMeta.from = res.from;
                        this.dataMeta.to = res.to;
                        this.currentPage.page = current_page;
                    })
                    .catch(() => {
                        this.loading = false;
                    });
            } else {
                this.getPrices();
            }
        },
        filterPage(value) {
            this.filtro = value;
        },
    },
};
</script>

<style></style>
